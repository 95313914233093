// src/components/About.js
import React from 'react';

const About = () => {
  return (
    <section className="p-4">
      <div className="max-w-3xl mx-auto text-center mb-80" id='about'>
        <h2 className="text-3xl font-bold mb-6" >🤓 About Me</h2>
        <p className="text-lg mb-8 text-left mb-20">
            I'm a passionate mobile developer with a keen focus on quality and user experience. With extensive experience in creating
            efficient, scalable applications, I thrive in environments that require innovation and problem-solving. My expertise
            includes working with both native and cross-platform technologies, and I take pride in delivering high-quality code that
            meets the needs of users and stakeholders alike.
          </p>
          <p className="text-lg mb-8 text-left mb-20">
            My journey has taken me through various industries, including fintech, e-commerce, and enterprise solutions. I've had the
            opportunity to work on projects that require deep technical knowledge, a commitment to excellence, and the ability to
            adapt to new challenges. Whether collaborating with a team or tackling solo projects, my goal is always to create
            intuitive and accessible applications that enhance the lives of users.
          </p>
          <p className="text-lg mb-8 text-left mb-20">
            When I'm not coding, you can find me exploring new places, gaming with friends, or listening to the latest music hits.
            I'm a firm believer in the power of technology to transform lives and am always excited about the next big thing in mobile
            development.
          </p>
        <div className="flex flex-wrap justify-center gap-8">
          <div className="flex flex-col items-center">
            <p className='text-5xl'>✈️</p>
          </div>
          <div className="flex flex-col items-center">
            <p className='text-5xl'>🎮</p>
          </div>
          <div className="flex flex-col items-center">
            <p className='text-5xl'>🎧</p>
          </div>
          <div className="flex flex-col items-center">
            <p className='text-5xl'>🍕</p>
          </div>
          <div className="flex flex-col items-center">
            <p className='text-5xl'>📷</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;

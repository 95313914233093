// src/components/Contact.js
import React from 'react';
import { FaInstagram, FaXTwitter } from 'react-icons/fa6';
import { LuMail } from "react-icons/lu";



const Contact = () => {
  return (
    <section className="p-4">
      <div className="max-w-3xl mx-auto text-center mt-20">
        <div className="flex justify-center gap-8 mb-10">
          <div className="flex flex-col items-center">
            <a href="mailto:agponcedl@gmail.com" rel="noopener noreferrer">
              <LuMail className="text-4xl mb-2 cursor-pointer hover:text-gray-500" />
            </a>
          </div>
          <div className="flex flex-col items-center">
            <a href="https://www.instagram.com/agustin.pdl/" target="_blank" rel="noopener noreferrer">
              <FaInstagram className="text-4xl mb-2 cursor-pointer hover:text-gray-500" />
            </a>
          </div>
          <div className="flex flex-col items-center">
            <a href="https://x.com/agustinponcedl" target="_blank" rel="noopener noreferrer">
              <FaXTwitter className="text-4xl mb-2 cursor-pointer hover:text-gray-500" />
            </a>
          </div>
        </div>
        <p className='text-gray-600 font-light'>Made with ❤️‍🔥</p>
      </div>
    </section>
  );
}

export default Contact;

import './App.css';
import NavBar from './components/navbar.jsx';
import Home from './components/home.jsx';
import Experience from './components/experience.jsx';
import About from './components/about.jsx';
import Contact from './components/contact.jsx';

function App() {
  return (
    <div className="App bg-gray-100" data-scroll-container>
      <NavBar />
      <div data-scroll-section>
        <Home />
      </div>
      <div data-scroll-section>
        <About />
      </div>
      <div data-scroll-section>
        <Experience />
      </div>
      <div data-scroll-section>
        <Contact />
      </div>
    </div>
  );
}

export default App;

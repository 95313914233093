// src/components/About.js
import React from 'react';
import memoji from '../assets/memoji.png'; // Ensure the path is correct

const Home = () => {
  return (
    <section className="h-screen flex items-center justify-center p-4" id='home'>
      <div className="flex flex-col md:flex-row items-center justify-center w-full">
        <img src={memoji} alt="Profile" className="w-48 h-48 rounded-full mb-4 md:mb-0 md:mr-4" />
        <div className="text-center md:text-left">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">
            Hey, I'm <span style={{ color: 'rgb(147, 112, 219)' }}>Agustín</span>.
          </h1>
          <p className="text-lg md:text-2xl"> I'm a software developer, and I create robust, user-friendly mobile applications.</p>
        </div>
      </div>
    </section>
  );
}

export default Home;

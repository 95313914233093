/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import '../index.css';
import { Link } from 'react-scroll';
import '../styles.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const controlNavbar = () => {
    if (window.scrollY > lastScrollY) {
      // If scroll down, hide the navbar
      setShowNavbar(false);
    } else {
      // If scroll up, show the navbar
      setShowNavbar(true);
    }
    setLastScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', controlNavbar);
    return () => {
      window.removeEventListener('scroll', controlNavbar);
    };
  }, [lastScrollY]);

  return (
    <nav className={`bg-gray-100 text-black p-4 fixed w-full transition-transform duration-300 ${showNavbar ? 'transform translate-y-0' : 'transform -translate-y-full'}`}>
      <div className="flex justify-between items-center">
        <div className="text-2xl"></div>
        <div className="md:hidden">
          <button onClick={toggleMenu}>
            {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        </div>
        <ul className={`md:flex md:space-x-4 ${isOpen ? 'block' : 'hidden'} md:block`}>
          <li className="text-base cursor-pointer hover-text-rgb">
            <Link to='home' spy={true} smooth={true} offset={-50} duration={500} className="block py-2 md:py-0">Home</Link>
          </li>
          <li className="text-base cursor-pointer hover-text-rgb">
            <Link to='about' spy={true} smooth={true} offset={-225} duration={500} className="block py-2 md:py-0">About</Link>
          </li>
          <li className="text-base cursor-pointer hover-text-rgb">
            <Link to='journey' spy={true} smooth={true} offset={-50} duration={500} className="block py-2 md:py-0">Journey</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;

// src/components/Experience.js
import React, { useEffect, useState } from 'react';
import experienceData from '../data/experience.json';

const Experience = () => {
  const [experiences, setExperiences] = useState([]);

  useEffect(() => {
    setExperiences(experienceData);
  }, []);

  return (
    <section className="p-4 flex justify-center">
      <div className="max-w-3xl w-full" id='journey'>
        <h2 className="text-3xl font-bold mb-6 text-center">✨ My Journey So Far</h2>
        {experiences.map((exp, index) => (
          <div key={index} className="mb-6 text-left">
            <h3 className="text-2xl font-semibold">{exp.jobTitle}</h3>
            <p className="text-xl text-gray-600">{exp.company} | {exp.startDate} - {exp.endDate}</p>
            <p className="text-lg mt-2 whitespace-pre-line">{exp.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Experience;